import React from 'react';
import { string, oneOfType, object } from 'prop-types';
import { Box, Heading, Text, Button } from '@chakra-ui/react';
import { HEADING_STYLES } from '@constants/colors';
import { GOOGLE_MAPS_LINK } from '@constants/index';

import txtWording from './locales';
import useLang from '@hooks/useLang';
import useInvitation from '@hooks/useInvitation';

function WeddingDetail({ title, titleProps, info, subTitle, date, time, dresscode, location }) {
  const lang = useLang();
  const handleClickGoogleMaps = () => {
    window.open(GOOGLE_MAPS_LINK, '_blank');
  };
  const isInvitation = useInvitation();

  return (
    <Box marginBottom="24px" color="mainColorText" >
      <Heading
        {...HEADING_STYLES}
        paddingLeft="0px"
        letterSpacing="1px"
        textAlign="left"
        margin="8px 0"
        fontSize="lg"
        {...titleProps}
      >
        {title}
      </Heading>

      <Text fontSize="md">{date}</Text>
      <Text fontSize="md">{time}</Text>
      
      <Text fontSize="md">{subTitle}</Text>
      {info && (
        <Text fontSize="md" fontStyle="italic">
          {`*) ${info}`}
        </Text>
      )}
      {location && isInvitation && <Button
          size="sm"
          onClick={handleClickGoogleMaps}          
          backgroundColor="bgAlternative"
          textColor="mainColorTextLight"
          fontSize='md'
          position='center'
          style={{fontStyle: 'italic'}}
          paddingBottom="2px"
          marginTop="16px"
      >
          {txtWording.location[lang]}
      </Button>}
      {dresscode && (
        <Text fontSize="md" marginTop="16px">
          {`Dresscode: ${dresscode}`}
      </Text>
      )}
    </Box>
  );
}

WeddingDetail.propTypes = {
  date: string.isRequired,
  dresscode: string,
  info: string,
  subTitle: oneOfType([string, object]),
  time: string.isRequired,
  title: string.isRequired,
  titleProps: object,
  location: string.isRequired,
};

WeddingDetail.defaultProps = {
  dresscode: '',
  titleProps: {},
};

export default React.memo(WeddingDetail);
