import React from 'react';
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalOverlay,
  ModalCloseButton,
  Heading,
  Text,
  Link,
} from '@chakra-ui/react';
import { string, func, bool } from 'prop-types';

import useFamily from '@hooks/useFamily';
import { HEADING_STYLES } from '@constants/colors';

const HEADING = {
  margin: '32px 0 24px 0',
  fontSize: 'lg',
  letterSpacing: '1px',
};

function BaliGuestInformation({ visible, onClose }) {
  const isInvitation = useFamily();

  return (
    <Modal size="full" onClose={onClose} isOpen={visible} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton onClick={onClose} />
        <ModalBody margin="0" padding="42px 24px 62px 24px">
          <Heading {...HEADING_STYLES}>
            {isInvitation ? `FAMILY INFORMATION` : 'GUEST INFORMATION'}{' '}
          </Heading>

          <Heading {...HEADING}>COMPLIMENTARY HOTEL</Heading>
          <Text marginBottom="16px">
            FOUR POINTS BY SHERATON BALI, UNGASAN <br />
            Jl. Raya Uluwatu Banjar Giri Dharma, Ungasan, <br /> Kec. Kuta Selatan, Bali 80364.
            <br />
            <b>Check in: </b>
            {isInvitation
              ? `Fri, 24 June 2022, 14.00 GMT+8 `
              : 'Sat, 25 June 2022, 14.00 GMT+8'}{' '}
            <br />
            <b>Check out:</b> Sun, 26 June 2021, 12.00 GMT+8 <br />
            {isInvitation && <b>TEPAI DINNER: Fri, 24 June 2022, 18.00 GMT+8</b>}
          </Text>
          <Link
            href="https://g.page/fourpointsbaliungasan?share"
            marginTop="16px"
            fontStyle="italic"
            color="#CFB4B4"
            fontWeight="bold"
            textDecor="underline"
          >
            See Location
          </Link>
          {/* TRANSPORTATION */}
          <Heading {...HEADING}>TRANSPORTATION</Heading>
          <Text marginBottom="16px">
            We will arrange transportation from Four Points by Sheraton Ungasan to the wedding venue
            and back. Shuttle Schedule can be found at Four Points by Sheraton Ungasan Hotel Lobby.
          </Text>
          {/* DRESSCODE  */}
          <Heading {...HEADING}>DRESSCODE</Heading>
          <Text marginBottom="16px">
            Casual | {isInvitation ? 'Color White or Champagne' : 'Color White'} <br />
            Notes: We are having a garden cliff party. <br />
            Dancing shoes are required.
          </Text>
          {/* WHAT TO DO?   */}
          <Heading {...HEADING}>WHAT TO DO?</Heading>
          <Text marginBottom="16px">
            Om Swastiastu, welcome to Bali! <br />
            See what you can do here:
          </Text>
          <Link
            href="https://balitourismboard.or.id/"
            marginTop="16px"
            fontStyle="italic"
            color="#CFB4B4"
            fontWeight="bold"
            textDecor="underline"
          >
            Bali tourism board
          </Link>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

BaliGuestInformation.propTypes = {
  lang: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

BaliGuestInformation.defaultProps = {
  lang: 'id',
};

export default BaliGuestInformation;
