import getLocale from '@/helpers/getLocale';

export const txtTitle = {
  id: `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga.
  Mohon konfirmasi kehadiran Anda melalui formulir reservasi di bawah ini:`,
  en: `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga.
  Mohon konfirmasi kehadiran Anda melalui formulir reservasi di bawah ini:`,
};

export const txtForm = {
  name: getLocale('Nama', 'Nama'),
  nameHelper: getLocale(
    'RSVP bersifat personal sehingga hanya bisa mengkonfirmasikan 1 nama tamu undangan saja',
    'RSVP bersifat personal sehingga hanya bisa mengkonfirmasikan 1 nama tamu undangan saja',
  ),
  hp: getLocale('Nomor HP', 'Nomor HP'),
  address: getLocale('Alamat', 'Alamat'),
  willYoutAttend: getLocale('Apakah anda akan hadir?', 'Apakah anda akan hadir?'),
  willAttend: getLocale('Ya, saya akan hadir', 'Ya, saya akan hadir'),
  noAttend: getLocale('Tidak, saya tidak bisa hadir', `Tidak, saya tidak bisa hadir`),
  willYouBringPartner: getLocale(
    'Berapa jumlah tamu yang akan hadir?',
    `Berapa jumlah tamu yang akan hadir?`,
  ),
  withPartner: getLocale('Ya, saya akan membawa 1 partner', `Yes, I will bring 1 partner here`),
  noPartner: getLocale('Tidak, saya akan hadir sendiri', `No, I will be by myself`),
  submit: getLocale('Kirim Konfirmasi >>', `Kirim Konfirmasi >>`),
  required: getLocale('Wajib diisi', 'Wajib diisi'),
  success: getLocale('Sukses!', 'Success!'),
  msgSuccess: getLocale(
    'Formulir reservasi kamu berhasil dikirim. Terima Kasih!',
    'Formulir reservasi kamu berhasil dikirim. Terima Kasih!',
  ),
  msgError: getLocale(
    'Ada kesalahan ketika mengirimkan reservasi kamu. Silahkan coba beberapa saat lagi.',
    'Ada kesalahan ketika mengirimkan reservasi kamu. Silahkan coba beberapa saat lagi.',
  ),
  singleGuestInfo: getLocale(
    `*) Berdasarkan kondisi saat ini, satu tamu hanya boleh membawa 1 orang partner atau pasangan dalam satu undangan. Terima kasih atas pengertiannya.`,
    `*) Adjusting to current situation, we are only able to accommodate single guest on this invitation. Thank you for your cooperation.`,
  ),
  invalidEmail: getLocale('Email tidak valid', 'Email tidak valid'),
  invalidPhone: getLocale('Nomor HP tidak valid', 'Nomor HP tidak valid'),
};
