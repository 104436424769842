import React from 'react';
import { bool, func, string } from 'prop-types';

import { Drawer, DrawerOverlay, DrawerContent, DrawerBody } from '@chakra-ui/react';

import Confirmation from '../Confirmation/Lazy';

function ModalConfirmation({ lang, isOpen, onClose }) {
  return (
    <Drawer size="full" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />
      <DrawerContent maxWidth="500px">
        <DrawerBody padding="0">
          <Confirmation onBack={onClose} lang={lang} />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

ModalConfirmation.propTypes = {
  isOpen: bool.isRequired,
  lang: string.isRequired,
  onClose: func.isRequired,
};

export default ModalConfirmation;
