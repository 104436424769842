import get from '@/helpers/getLocale';

export default {
  title: get('Tanda Kasih', 'Send Gift'),
  desc: get(
    `Bagi yang ingin memberikan tanda kasih, dapat mengirimkan melalui fitur di bawah ini:`,
    `If you would like to express your kind wishes by sending us a gift, please use the button below:`,
  ),
  confirm: get('Konfirmasi', 'Confirmation'),
  sendGift: get('Kirim Hadiah', 'Send Gift'),
  modalDesc: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
    dapat melakukan transfer melalui rekening berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly tap the button down below:`,
  ),
  modalCopy: get('Salin', 'Copy'),
  modalDescAddress: get(
    `Bagi Bapak/Ibu/Saudara/i yang ingin memberikan tanda kasih kepada mempelai dan keluarga
  dapat mengirimkan hadiah di alamat berikut:`,
    `For beloved one who may want to showing your sincere love with sending gift, please kindly send to this address below:`,
  ),
  success: get('Sukses!', 'Success!'),
  successCopyRekening: get(
    'Nomor rekening berhasil disalin.',
    'Account number copied successfully.',
  ),
  successCopyAddress: get(
    'Alamat berhasil disalin.',
    'Address copied successfully.',
  ),
  failedCopied: get(
    'Terjadi kesalahan. Mohon muat ulang browser Anda atau coba lagi nanti.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
};